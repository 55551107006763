import { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import Slider from 'react-slick';
import { useWindowDimensions, newLineToBr } from '../../utilities/Helper';
import { IconArrowLeft, IconArrowRight } from '@/snippets/index';
import styles from './CategoryCarousel.module.css';
import { Image } from '@/snippets/components/Image';

function Section({ cms }) {
  const categories = cms?.categories || [];
  const [filteredCategories, setFilteredCategories] = useState(categories);
  const textColor = cms?.textColor || 'black';
  const groupBtnBgColor = cms?.textColor === 'black' ? 'white' : 'black';
  const textAlign = cms?.textAlign || 'md-:text-left';
  const textAlign_m = cms?.textAlign_m || 'text-left';
  const enableGroups = cms?.enableGroups || false;
  const enableMobileGrid = cms?.enableMobileGrid || false;
  const allGroups = categories.map(
    (catItem) => catItem?.category?.categoryGroup
  ).filter((group) => !!group);
  const validGroups = [...new Set(allGroups)].splice(0, 3);

  const scrollRef = useRef(null);
  const sliderContainer = useRef(null);
  const { vpWidth } = useWindowDimensions();
  const [enableArrows, setEnableArrows] = useState(0);
  const renderAsGrid = enableMobileGrid && vpWidth < 1024;

  const [activeGroup, setActiveGroup] = useState(validGroups[0]);

  const slickNext = () => {
    scrollRef.current.slickNext();
  };
  const slickPrev = () => {
    scrollRef.current.slickPrev();
  };
  const checkArrow = (arrow) => {
    let isDisabled = true;
    if (scrollRef.current && sliderContainer.current) {
      const activeSlides =
        sliderContainer.current.querySelectorAll('.slick-active');
      const slidesLenght =
        sliderContainer.current.querySelectorAll('.slick-slide').length - 1;
      switch (arrow) {
        case 'next':
          isDisabled =
            parseInt(
              activeSlides[activeSlides.length - 1].dataset.index,
              10
            ) === slidesLenght;
          break;
        case 'prev':
          isDisabled = parseInt(activeSlides[0].dataset.index, 10) === 0;
          break;
        default:
          break;
      }
    }
    return isDisabled;
  };

  const [prevArrowDisabled, setPrevArrowDisabled] = useState(true);
  const [nextArrowDisabled, setNextArrowDisabled] = useState(false);

  const slideSettings = {
    infinite: false,
    centerMode: false,
    variableWidth: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    swipeToSlide: false,
    swipe: true,
    useTransform: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.5,
          infinite: false,
          adaptiveHeight: false,
          dots: true,
        },
      },
    ],
    afterChange: () => {
      setNextArrowDisabled(checkArrow('next'));
      setPrevArrowDisabled(checkArrow('prev'));
    },
  };

  useEffect(() => {
    if (scrollRef.current) {
      const child = scrollRef.current.innerSlider.props.children;
      setEnableArrows(child.length > scrollRef.current.props.slidesToShow);
      scrollRef.current.slickGoTo(0);
      if (child.length > scrollRef.current.props.slidesToShow) {
        setNextArrowDisabled(checkArrow('next'));
        setPrevArrowDisabled(checkArrow('prev'));
      }
    }
  }, [vpWidth, filteredCategories]);

  useEffect(() => {
    if (enableGroups) {
      setFilteredCategories(
        categories.filter(
          (loopCategory) => loopCategory?.category?.categoryGroup === activeGroup
        )
      );
    } else {
      setFilteredCategories(categories);
    }
  }, [enableGroups, activeGroup]);

  return (
    <div
      key={cms.cmsId || cms.tinaId}
      id={`category-carousel-${cms.cmsId || cms.tinaId}`}
      className={`category-carousel relative overflow-hidden ${
        styles['category-carousel']
      } text-${textColor} ${
        cms?.bgColor !== 'bg-white' && cms?.bgColor !== 'transparent'
          ? ` pt-9 pb-9 sm:pt-12 sm:pt-12 md:pt-16 md:pb-16 ${cms?.bgColor}`
          : ``
      }`}
      data-comp={Section.displayName}
      style={{ backgroundColor: cms?.customBgColor }}
    >
      <style>
        {`#category-carousel-${cms.cmsId || cms.tinaId} {
            margin-top: ${cms.mobileSpacing}px;
          }
          @media(min-width: 1024px) {
            #category-carousel-${cms.cmsId || cms.tinaId} {
              margin-top: ${cms.desktopSpacing}px;
            }
          }`}
      </style>
      <div
        className={`container ${
          filteredCategories.length <= 4 && enableGroups === false
            ? 'lg:ml-auto lg:!max-w-[992px] lg:pr-[18px] 2xl:ml-auto 2xl:!max-w-[1180px]'
            : 'lg:ml-12 2xl:ml-[126px]'
        }`}
      >
        <div
          className={`md:mr-auto ${
            filteredCategories.length <= 4
              ? 'px-4 md:px-12 lg:px-0'
              : 'px-4 md:px-12 lg:mr-[8rem] lg:px-0'
          }`}
        >
          <div className={`mb-6 md:mb-9 ${textAlign_m} ${textAlign}`}>
            {cms?.section_optional_title && (
              <div
                className={`mb-4 max-w-[546px] font-gothamBold text-base md:mb-2 ${
                  textAlign === 'md:text-center' ? 'md:mx-auto' : 'md:mx-0'
                } ${textAlign_m === 'text-center' ? 'mx-auto' : ''}`}
              >
                {cms?.section_optional_title}
              </div>
            )}
            {cms?.section_title && (
              <h2
                className={`text-header mt-0 mb-3 max-w-[546px] lg:mb-4 ${
                  textAlign === 'md:text-center' ? 'md:mx-auto' : 'md:mx-0'
                } ${textAlign_m === 'text-center' ? 'mx-auto' : ''}`}
              >
                {cms?.section_title}
              </h2>
            )}
            {cms?.section_description && (
              <p
                className={`m-0 max-w-[546px] text-h6-m font-light md:text-h6 ${
                  textAlign === 'md:text-center' ? 'md:mx-auto' : 'md:mx-0'
                } ${textAlign_m === 'text-center' ? 'mx-auto' : ''}`}
                dangerouslySetInnerHTML={{
                  __html: newLineToBr(cms?.section_description),
                }}
              />
            )}
            {enableGroups === true && validGroups.length > 1 && (
              <div
                className={`mt-4 mb-4 flex flex-wrap gap-x-4 gap-y-3 ${
                  textAlign === 'md:text-center'
                    ? 'md:justify-center'
                    : 'md:justify-start'
                }
              ${textAlign_m === 'text-center' ? 'justify-center' : ''}`}
              >
                {validGroups.map((group) => {
                  return (
                    <button
                      key={group}
                      type="button"
                      onClick={() => setActiveGroup(group)}
                      className={`inline-block rounded-full border-2 px-4 py-1 font-gothamBold text-xs leading-tight border-${textColor} transition-all duration-150 ${
                        activeGroup === group
                          ? `bg-${textColor} text-${groupBtnBgColor}`
                          : `border-${textColor} text-${textColor} ${
                              textColor === 'black'
                                ? 'lg:hover:bg-grey-1 lg:hover:text-white'
                                : 'lg:hover:bg-white lg:hover:text-black'
                            }`
                      }`}
                    >
                      {group}
                    </button>
                  );
                })}
              </div>
            )}
          </div>
          {filteredCategories.length > 0 && (
            <div
              className={`group relative -ml-4 -mr-4 ${
                filteredCategories.length > 4 ? '' : 'px-0 '
              } slider-container`}
              ref={sliderContainer}
            >
              {renderAsGrid === false ? (
                <>
                  <Slider
                    {...slideSettings}
                    className={`text-${textColor}`}
                    ref={scrollRef}
                  >
                    {filteredCategories.map((category, index) => {
                      return (
                        <Category
                          key={index}
                          item={category?.category}
                          textColor={textColor}
                        />
                      );
                    })}
                  </Slider>
                  {enableArrows && (
                    <div
                      className={`absolute top-1/2 flex w-full -translate-y-1/2 items-center justify-between pr-4 pl-8 opacity-0 transition-all duration-150 group-hover:opacity-100`}
                    >
                      <button
                        type="button"
                        onClick={() => slickPrev()}
                        className={`flex h-11 w-11 items-center justify-center rounded-full bg-black text-white
                        ${
                          prevArrowDisabled
                            ? 'pointer-events-none opacity-50'
                            : ''
                        }`}
                      >
                        <IconArrowLeft />
                        <span className="sr-only">Prev</span>
                      </button>
                      <button
                        type="button"
                        onClick={() => slickNext()}
                        className={`ml-[-1px] flex h-11 w-11 items-center justify-center rounded-full bg-black text-white
                        ${
                          nextArrowDisabled
                            ? 'pointer-events-none opacity-50'
                            : ''
                        }`}
                      >
                        <IconArrowRight />
                        <span className="sr-only">Next</span>
                      </button>
                    </div>
                  )}
                </>
              ) : (
                <div className="flex flex-wrap justify-center gap-x-4 gap-y-6 px-4">
                  {filteredCategories.map((category, index) => {
                    return (
                      <Category
                        key={index}
                        item={category?.category}
                        textColor={textColor}
                        isGrid
                      />
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function Category({ item, textColor, isGrid, className}) {
  if (!item?.image?.src && !item?.title) return null;

  return (
    <div
      className={`${
        styles.category
      } text-${textColor} shrink-0 text-sm md:text-base ${
        isGrid == true ? '' : 'pl-4'
      } ${isGrid === true ? styles['category-grid'] : ''}`}
    >
      {item?.link ? (
        <Link href={item?.link}>
          <a
            className={`block border no-underline border-${textColor} h-full text-${textColor}`}
            href={item?.link}
          >
            {item?.image?.src && (
              <div className={`responsive-image ${styles.category__image}`}>
                {item?.image_hover?.src && (
                  <Image
                    src={item?.image_hover?.src}
                    loading="lazy"
                    sizes="(max-width:480px) 75vw,(max-width:1024px) 33vw, 25w"
                    fill
                    className={`invisible w-full ${styles.category__hoverImage}`}
                    alt={item?.hoverImageAlt}
                  />
                )}
                <Image
                  src={item?.image?.src}
                  loading="lazy"
                  sizes="(max-width:480px) 75vw,(max-width:1024px) 33vw, 25w"
                  fill
                  className="w-full opacity-100"
                  alt={item?.imageAlt}
                />
              </div>
            )}
            <div
              className={`${styles.category__title} border-t transition-all border-${textColor}`}
            >
              {item?.title && (
                <p className="m-0 block border-2 border-transparent p-4 text-center font-gothamBold text-sm md:p-5 md:text-base">
                  {item?.title}
                </p>
              )}
            </div>
          </a>
        </Link>
      ) : (
        <div className={`border border-${textColor} h-full`}>
          {item?.image?.src && (
            <div className={`responsive-image ${styles.category__image}`}>
              {item?.image_hover?.src && (
                <img
                  src={item?.image_hover?.src}
                  loading="lazy"
                  className={`invisible w-full ${styles.category__hoverImage}`}
                  alt=""
                />
              )}
              <img
                src={item?.image?.src}
                loading="lazy"
                className="w-full"
                alt={item?.imageAlt}
              />
            </div>
          )}
          <div
            className={`${styles.category__title} border-t transition-all border-${textColor}`}
          >
            {item?.title && (
              <p className="m-0 block border-2 border-transparent p-4 text-center font-gothamBold text-sm md:p-5 md:text-base">
                {item?.title}
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

Section.displayName = 'Category Carousel';
Category.displayName = 'Category';

Section.Schema = {
  category: 'Modules',
  label: Section.displayName,
  key: 'category-carousel',
  fields: [
    {
      component: 'number',
      name: 'mobileSpacing',
      label: 'Section Top Margin - Mobile',
      defaultValue: 60,
    },
    {
      component: 'number',
      name: 'desktopSpacing',
      label: 'Section Top Margin - Desktop',
      defaultValue: 80,
    },
    {
      component: 'toggle',
      name: 'enableMobileGrid',
      label: 'Enable Mobile Grid',
      defaultValue: false,
      toggleLabels: {
        true: 'Enable',
        false: 'Disable',
      },
    },
    {
      component: 'toggle',
      name: 'enableGroups',
      label: 'Enable Groups',
      defaultValue: false,
      toggleLabels: {
        true: 'Enable',
        false: 'Disable',
      },
    },
    {
      component: 'group-list',
      name: 'categories',
      label: 'Category',
      description: 'Categories',
      fields: [
        {
          label: 'Category',
          name: 'category',
          component: 'group',
          fields: [
            {
              label: 'Category Group',
              name: 'categoryGroup',
              component: 'text',
              description: 'Label to group categories together, you can use up to three different groups, anything over this number will be ignored, the groups are counted from top to bottom in population'
            },
            {
              label: 'Category Image',
              name: 'image',
              component: 'image',
              description: 'Recommended image size: 530px x 640px',
            },
            {
              name: 'imageAlt',
              label: 'Category Image Alt text',
              component: 'text',
            },
            {
              label: 'Category Hover Image',
              name: 'image_hover',
              component: 'image',
              description: 'Recommended image size: 530px x 640px',
            },
            {
              name: 'hoverImageAlt',
              label: 'Category Hover Image Alt text',
              component: 'text',
            },
            {
              label: 'Category Title',
              name: 'title',
              component: 'text',
            },
            {
              label: 'Category URL',
              name: 'link',
              component: 'text',
            },
          ],
        },
      ],
    },
    {
      name: 'section_optional_title',
      label: 'Section Pre-title',
      component: 'text',
    },
    {
      name: 'section_title',
      label: 'Section Title',
      component: 'text',
    },
    {
      name: 'section_description',
      label: 'Section Description',
      component: 'textarea',
    },
    {
      component: 'select',
      name: 'textColor',
      label: 'Text Color',
      options: [
        { label: 'Light', value: 'white' },
        { label: 'Dark', value: 'black' },
      ],
      defaultValue: 'black',
    },
    {
      component: 'select',
      name: 'textAlign',
      label: 'Text Align (Desktop)',
      options: [
        { label: 'Left', value: 'md:text-left' },
        { label: 'Center', value: 'md:text-center' },
      ],
      defaultValue: 'md:text-left',
    },
    {
      component: 'select',
      name: 'textAlign_m',
      label: 'Text Align (Mobile)',
      options: [
        { label: 'Left', value: 'text-left' },
        { label: 'Center', value: 'text-center' },
      ],
      defaultValue: 'text-left',
    },
    {
      component: 'select',
      name: 'bgColor',
      label: 'Background Color',
      description: 'For section background color, select one from preselected brand colors.',
      options: [
        { label: 'White', value: 'bg-white' },
        { label: 'Grey', value: 'bg-grey-1' },
        { label: 'Light Grey', value: 'bg-grey-9' },
        { label: 'Blue 1', value: 'bg-quinary-1' },
        { label: 'Blue 2', value: 'bg-quinary-2' },
        { label: 'Blue 3', value: 'bg-light-blue' },
        { label: 'Cream 1', value: 'bg-tertiary-2' },
        { label: 'Cream 2', value: 'bg-senary-4' },
        { label: 'Purple', value: 'bg-tertiary-1' },
        { label: 'Green', value: 'bg-quaternary-1' },
        { label: 'Pink 1', value: 'bg-primary-2' },
        { label: 'Pink 2', value: 'bg-secondary-1' },
        { label: 'Yellow', value: 'bg-yellow' },
        { label: 'Orange', value: 'bg-secondary-2' },
        { label: 'Seafoam 1', value: 'bg-quaternary-2' },
        { label: 'Seafoam 2', value: 'bg-senary-3' },
        { label: 'Air Dry Cream', value: 'bg-stylers-1' },
        { label: 'Sculpting Cream', value: 'bg-stylers-2' },
        { label: 'Texture Foam', value: 'bg-stylers-3' },
        { label: 'Sleek Stick', value: 'bg-stylers-4' }
      ],
      defaultValue: 'bg-white',
    },
    {
      component: 'color',
      name: 'customBgColor',
      label: 'Custom Background Color',
      description: 'Custom Background color',
    },
  ],
};

export const CategoryCarousel = Section;
