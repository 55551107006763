import { useMemo } from 'react';
import { useCartAddItem, useProductByHandle } from '@backpackjs/storefront';
import Slider from 'react-slick';
import Link from 'next/link.js';
import { useYotpoProductBottomline } from '@hooks';
import { YotpoStars } from '@/snippets/components/YotpoStars';
import { getSizedImageUrl } from '../../utilities/Images';
import { getMetafieldMap } from '@/utils/Metafields';
import ProductPrice from '@/snippets/globals/layout/ProductPrice';
import ProductBadge from '@/snippets/products/ProductBadge';
import { Image } from '@/snippets/components/Image';

function Section({ cms }) {
  const { cartAddItem } = useCartAddItem();
  const addItemToCart = async (id) => {
    await cartAddItem(
      {
        merchandiseId: id,
        quantity: 1,
        attributes: [
          {
            key: '_sc',
            value: (typeof window.ShipScoutLineItemProperty !== 'undefined' ? window.ShipScoutLineItemProperty : 'null'),
          }
        ],
      },
      () => {
        window.dispatchEvent(new CustomEvent('openSideCart'));
      }
    );
  };

  const itemCount = cms?.productupsells?.length || 0;

  if (itemCount <= 0) {
    return null;
  }

  return (
    <div
      id={`${Section.Schema.key}-${cms.id || cms.cmsId || cms.tinaId}`}
      data-comp="productupsells"
    >
      <style>
        {`#${Section.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
            margin-top: ${cms.mobileSpacing}px;
          }
          @media(min-width: 1024px) {
            #${Section.Schema.key}-${cms.id || cms.cmsId || cms.tinaId} {
              margin-top: ${cms.desktopSpacing}px;
            }
          }`}
      </style>
      <div
        className={`product-upsells inner-product-upsells-container flex flex-col items-center justify-center`}
      >
        {cms?.section_title && (
          <p className="sub-regular mb-[16px] text-center font-normal">
            {cms.section_title}
          </p>
        )}
        {cms?.productupsells?.length > 1 && (
          <div className="product-upsells-container w-full -pr-4 m-auto overflow-hidden ml-0 -mr-0 m-0 px-0 text-left border-grey-4 xl-plus:border-t">
              {cms?.productupsells?.map((upsellItem, index) => {
              return (
                <UpsellItem
                  key={index}
                  upsellItem={upsellItem}
                  index={index}
                  addItemToCart={addItemToCart}
                />
              );
            })}
          </div>
        )}
        {cms?.productupsells?.length === 1 && (
          <div className="product-main-upsell-container flex justify-center border-grey-4">
            {cms?.productupsells?.map((upsellItem, index) => {
              return (
                <UpsellItem
                  key={index}
                  upsellItem={upsellItem}
                  index={index}
                  addItemToCart={addItemToCart}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

function UpsellItem({ upsellItem, index, addItemToCart }) {
  const { product } = useProductByHandle({
    handle: upsellItem?.productName?.handle?.replace('/products/', ''),
  });
  const metafieldMap = useMemo(() => {
    return getMetafieldMap(product);
  }, [product?.id]);
  const imageUrl = useMemo(() => {
    return getSizedImageUrl(product?.featuredImage?.src, '200x200@2x');
  }, [product?.featuredImage?.src]);
  const productTitle = metafieldMap.custom_product_title || product?.title;
  const productIngredient = metafieldMap.custom_ingredient_title;
  const { bottomline } = useYotpoProductBottomline({
    legacyResourceId: product?.legacyResourceId,
  });

  if(!product) return null;

  return (
    <div
      className="product-upsell-container relative  mb-[55px] w-full snap-center border border-grey-4 xl-plus:mb-0 xl-plus:mb-auto xl-plus:border-t-0 xl-plus:border-r xl-plus:border-b xl-plus:!border-l"
      data-index={index}
    >
      <div className="w-full text-sm text-base">
        <div className="flex h-full w-full flex-row items-center">
          <Link href={`/products/${product?.handle}`}>
            <a className="product-upsell__image-container relative block aspect-square w-full w-auto bg-grey-9">
              <ProductBadge
                product={product}
                className="pointer-events-none !top-1 !right-2 z-10 !h-10 !w-10"
              />
              <div className="pointer-events-none aspect-square w-[140px]">
                {imageUrl && (
                  <Image
                    src={imageUrl}
                    alt={product?.featuredImage?.alt}
                    sizes="(max-width=1023px) 50vw, 15vw"
                    fill
                    loading="lazy"
                  />
                )}
              </div>
            </a>
          </Link>
          <div className="flex h-full w-full flex-col p-4 px-4 py-2 xl-plus:flex-row xl-plus:items-start xl-plus:px-6 xl-plus:py-0 xl-plus:py-3">
            <Link href={`/products/${product?.handle}`}>
              <a className="product-upsell__info-container flex w-full flex-col items-start no-underline">
                {productIngredient && (
                  <span className="text-xsmall pointer-events-none mb-[4px] text-left font-tiempos font-normal italic">
                    {productIngredient}
                  </span>
                )}
                <span className="text-small pointer-events-none mb-[6px] text-left font-tiempos font-normal leading-[19px]">
                  {productTitle}
                </span>
                <div
                  className={`yotpo-reviews pointer-events-none mb-[6px] min-h-[17.5px]`}
                >
                  {bottomline && bottomline.total_reviews > 0 ? (
                    <div className="flex flex-wrap items-center gap-1.5">
                      {bottomline.total_reviews ? (
                        <>
                          <YotpoStars rating={bottomline.average_score} />
                        </>
                      ) : (
                        <p className="mb-0 text-[13px] leading-[17.5px] underline">
                          No reviews
                        </p>
                      )}
                    </div>
                  ) : (
                    <div className="yotpo-no-reviews text-sm text-grey-1 underline">
                      No reviews
                    </div>
                  )}
                </div>
                <ProductPrice
                  variant={product?.variants[0]}
                  className="product-upsell-prices pointer-events-none mb-0 block !text-xs !text-black xl-plus:hidden"
                />
              </a>
            </Link>
            <div className="flex flex-col items-end justify-center">
              <ProductPrice
                variant={product?.variants[0]}
                className="product-upsell-prices pointer-events-none mb-4 hidden !text-xs !text-black xl-plus:block"
              />
              {product?.variants[0] && (
                <button
                  type="button"
                  className="product-upsell__atc-button text-xsmall button--primary absolute bottom-[-35px] z-[1] mr-[-1rem] mt-auto  w-full !min-w-0 py-2 px-4 text-center font-gothamBold text-white transition xl-plus:relative xl-plus:bottom-auto xl-plus:mr-auto xl-plus:mt-0 xl-plus:w-[120px]"
                  onClick={() => {
                    addItemToCart(product?.variants[0].id);
                  }}
                >
                  <span className="pointer-events-none mr-[4px]">add</span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Section.displayName = 'ProductUpsells';

Section.Schema = {
  category: 'Product',
  label: Section.displayName,
  key: 'productupsells',
  fields: [
    {
      component: 'number',
      name: 'mobileSpacing',
      label: 'Section Top Margin - Mobile',
      defaultValue: 60,
    },
    {
      component: 'number',
      name: 'desktopSpacing',
      label: 'Section Top Margin - Desktop',
      defaultValue: 80,
    },
    {
      name: 'section_title',
      label: 'Section Title',
      component: 'text',
    },
    {
      name: 'productupsells',
      label: 'Product Upsell',
      component: 'group-list',
      fields: [
        {
          component: 'productSearch',
          name: 'productName',
          label: 'Select Product',
        },
      ],
    },
  ],
};

export const ProductUpsells = Section;
