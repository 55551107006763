const SkipLink = () => {
  return (
    <a
      className="left-0 absolute -translate-y-full focus:translate-y-0 transition-transform duration-300 z-[10000] button button--primary inline-block"
      href="#main-content">
      Skip to content
    </a>
  )
}

export default SkipLink
